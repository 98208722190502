<template>
  <div>
    <h2 class="text-2xl sm:text-3xl mb-8 text-terminal-accent font-mono">
      blog posts
    </h2>
    <ul class="space-y-8">
      <li v-for="post in blogPosts" :key="post.slug" class="blog-post-card">
        <article>
          <h3
            class="text-xl mb-2 text-terminal-highlight hover:text-terminal-accent transition-colors duration-300"
          >
            <router-link
              :to="{ name: 'BlogPost', params: { slug: post.slug } }"
            >
              {{ post.title }}
            </router-link>
          </h3>
          <p class="mb-4 text-terminal-text">{{ post.summary }}</p>
          <p class="text-sm text-terminal-secondary">{{ post.date }}</p>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.post.title,
      meta: [
        { name: "description", content: this.post.summary },
        { property: "og:title", content: this.post.title },
        { property: "og:description", content: this.post.summary },
        {
          property: "og:url",
          content: `https://zaph.dev/blog/${this.post.slug}`,
        },
        { name: "twitter:card", content: "summary_large_image" },
      ],
    };
  },
  data() {
    return {
      blogPosts: [
        {
          slug: "galois",
          title:
            "galois: combining automatic parallelization and universal interoperability",
          summary: "an approach to solving modern computation",
          date: "2024-08-05",
        },
        {
          slug: "axioms",
          title: "how to derive all of math from the axioms",
          summary: "an overview of dependent types and theorem provers",
          date: "2024-07-29",
        },
        {
          slug: "church",
          title: "modern computation is fundamentally broken",
          summary:
            "the future of 21st century computing: church encodings and GPUs",
          date: "2024-07-28",
        },
      ],
    };
  },
};
</script>

<style scoped>
.blog-post-card {
  @apply bg-terminal-bg bg-opacity-50 border border-terminal-muted border-opacity-30 rounded-lg p-6 transition-all duration-300;
  position: relative;
  overflow: hidden;
}

.blog-post-card::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(92, 207, 230, 0.1), transparent);
  transition: top 0.3s ease;
  pointer-events: none;
}

.blog-post-card:hover::before {
  top: 0;
}

.blog-post-card:hover {
  @apply transform -translate-y-1;
  box-shadow: 0 4px 6px -1px rgba(92, 207, 230, 0.1),
    0 2px 4px -1px rgba(92, 207, 230, 0.06);
}

.blog-post-card a {
  display: block;
  height: 100%;
  width: 100%;
}
</style>
