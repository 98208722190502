import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './components/HomePage.vue'
import Projects from './components/ProjectList.vue'
import BlogList from './components/BlogList.vue'
import BlogPost from './components/BlogPost.vue'
import TheoremProver from './components/TheoremProver.vue'
import './index.css'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/projects', component: Projects },
    { path: '/blog', component: BlogList },
    { path: '/blog/:slug', name: 'BlogPost', component: BlogPost },
    { path: '/prover', component: TheoremProver },
    { path: '/:pathMatch(.*)*', redirect: '/' },
  ]
})

const app = createApp(App)
app.use(router)
app.mount('#app')
