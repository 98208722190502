<template>
  <nav class="flex flex-wrap items-center justify-start gap-4 text-lg">
    <router-link
      v-for="item in menuItems"
      :key="item.path"
      :to="item.path"
      class="hover:text-terminal-highlight transition-colors duration-300"
      active-class="text-terminal-highlight"
    >
      {{ item.name }}
    </router-link>
    <a
      href="https://github.com/jfulfo"
      target="_blank"
      rel="noopener noreferrer"
      class="hover:text-terminal-highlight transition-colors duration-300"
    >
      Github
    </a>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { name: "Home", path: "/" },
        { name: "Projects", path: "/projects" },
        { name: "Blog", path: "/blog" },
        // { name: "Theorem Prover", path: "prover" },
      ],
    };
  },
};
</script>

<style scoped>
a {
  position: relative;
  overflow: hidden;
}

a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #5ccfe6;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

a:hover::after {
  transform: scaleX(1);
}
</style>
