<template>
  <div>
    <h2 class="text-2xl sm:text-3xl mb-8 text-terminal-accent font-mono">
      projects
    </h2>
    <ul class="space-y-8">
      <li v-for="project in projects" :key="project.name" class="project-card">
        <article>
          <h3
            class="text-xl mb-2 text-terminal-highlight hover:text-terminal-accent transition-colors duration-300"
          >
            <a :href="project.link" target="_blank" rel="noopener noreferrer">
              {{ project.name }}
            </a>
          </h3>
          <p class="mb-4 text-terminal-text">{{ project.description }}</p>
          <div class="flex flex-wrap gap-2">
            <span
              v-for="tech in project.technologies"
              :key="tech"
              class="px-3 py-1 bg-terminal-muted bg-opacity-20 text-terminal-secondary rounded-full text-sm"
            >
              {{ tech }}
            </span>
          </div>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [
        {
          name: "multi-layer perceptron from scratch",
          description:
            "created perceptron libraries for C, Python, and Rust with minimal dependencies.",
          technologies: [
            "Python",
            "C",
            "Rust",
            "Machine Learning",
            "Library Development",
          ],
          link: "https://github.com/jfulfo/mlp_from_scratch",
        },
        {
          name: "real-time edge detection",
          description:
            "implemented a real-time edge detection algorithm using the canny method in Rust.",
          technologies: [
            "Rust",
            "Computer Vision",
            "Parallel Computing",
            "Image Processing",
          ],
          link: "https://github.com/jfulfo/uncanny",
        },
      ],
    };
  },
};
</script>

<style scoped>
.project-card {
  @apply bg-terminal-bg bg-opacity-50 border border-terminal-muted border-opacity-30 rounded-lg p-6 transition-all duration-300;
  position: relative;
  overflow: hidden;
}

.project-card::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(92, 207, 230, 0.1), transparent);
  transition: top 0.3s ease;
  pointer-events: none;
}

.project-card:hover::before {
  top: 0;
}

.project-card:hover {
  @apply transform -translate-y-1;
  box-shadow: 0 4px 6px -1px rgba(92, 207, 230, 0.1),
    0 2px 4px -1px rgba(92, 207, 230, 0.06);
}

.project-card a {
  display: block;
  height: 100%;
  width: 100%;
}
</style>
