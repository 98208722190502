<template>
  <div class="blog-post">
    <h1 class="text-3xl mb-4 text-terminal-accent">{{ title }}</h1>
    <div class="blog-content markdown-body" v-html="renderedContent"></div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { mathjax } from "mathjax-full/js/mathjax.js";
import { TeX } from "mathjax-full/js/input/tex.js";
import { CHTML } from "mathjax-full/js/output/chtml.js";
import { browserAdaptor } from "mathjax-full/js/adaptors/browserAdaptor.js";
import { RegisterHTMLHandler } from "mathjax-full/js/handlers/html.js";
import { AllPackages } from "mathjax-full/js/input/tex/AllPackages.js";

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: "description", content: this.summary },
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.summary },
        {
          property: "og:url",
          content: `https://zaph.dev/blog/${this.post.slug}`,
        },
        { name: "twitter:card", content: "summary" },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: this.title,
            description: this.summary,
            author: {
              "@type": "Person",
              name: "Zaphkiel",
            },
            datePublished: this.post.date,
            url: `https://zaph.dev/blog/${this.post.slug}`,
          },
        },
      ],
    };
  },
  data() {
    return {
      title: "",
      content: "",
      renderedContent: "",
      mathjax: null,
    };
  },
  mounted() {
    this.setupMathJax();
    this.fetchBlogPost();
  },
  methods: {
    setupMathJax() {
      const adaptor = browserAdaptor();
      RegisterHTMLHandler(adaptor);

      const tex = new TeX({
        packages: AllPackages,
        inlineMath: [
          ["$", "$"],
          ["\\(", "\\)"],
        ],
        displayMath: [
          ["$$", "$$"],
          ["\\[", "\\]"],
        ],
      });
      const chtml = new CHTML({
        fontURL:
          "https://cdn.jsdelivr.net/npm/mathjax@3/es5/output/chtml/fonts/woff-v2",
      });
      this.mathjax = mathjax.document(window.document, {
        InputJax: tex,
        OutputJax: chtml,
      });
    },
    async fetchBlogPost() {
      const slug = this.$route.params.slug;
      try {
        const response = await fetch(`/blog-posts/${slug}.md`);
        const markdown = await response.text();
        this.renderMarkdown(markdown);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    },
    renderMarkdown(markdown) {
      const md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(str, { language: lang }).value;
            } catch (__) {
              console.error(__);
            }
          }
          return "";
        },
      });

      md.renderer.rules.list_item_open = (tokens, idx) => {
        if (tokens[idx].markup === "-") {
          return '<li class="bullet-item">';
        }
        return "<li>";
      };

      md.renderer.rules.blockquote_open = (tokens, idx) => {
        if (tokens[idx].markup === ">") {
          return '<blockquote class="blockquote">';
        }
        return "<blockquote>";
      };

      this.renderedContent = md.render(markdown);

      this.$nextTick(() => {
        this.mathjax
          .findMath()
          .compile()
          .getMetrics()
          .typeset()
          .updateDocument();
      });
    },
  },
};
</script>

<style>
.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
  background-color: rgba(27, 29, 42, 0.8);
  border-radius: 8px;
}

@media (min-width: 640px) {
  .blog-post {
    padding: 2rem;
  }
}

.markdown-body {
  color: #f8f8f2;
  font-family: "Fira Code", monospace;
  font-size: 18px;
  line-height: 1.6;
}

.markdown-body h1 {
  font-size: 1.5em;
  color: #ff79c6;
  margin-bottom: 0.5em;
}

.markdown-body h2 {
  font-size: 1.25em;
  color: #8be9fd;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.markdown-body h3 {
  font-size: 1.1em;
  color: #bd93f9;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.markdown-body p {
  margin-bottom: 1em;
}

.markdown-body a {
  color: #bd93f9;
  text-decoration: none;
  transition: color 0.3s ease;
}

.markdown-body a:hover {
  color: #ff79c6;
}

.markdown-body img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.markdown-body pre {
  background-color: #1e2734;
  padding: 1em;
  border-radius: 4px;
  overflow-x: auto;
}

.markdown-body code {
  font-family: "Fira Code", monospace;
  font-size: 0.9em;
  background-color: rgba(189, 147, 249, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.markdown-body ul {
  list-style-type: none;
  padding-left: 1.5em;
}

.markdown-body li.bullet-item {
  position: relative;
  margin-bottom: 0.5em;
}

.markdown-body li.bullet-item::before {
  content: "•";
  color: #5ccfe6;
  position: absolute;
  left: -1.5em;
  top: 0;
}

.markdown-body blockquote {
  border-left: 4px solid #ff79c6;
  padding-left: 1em;
  margin: 0;
}

.markdown-body .MathJax {
  font-size: 1.1em;
  overflow-x: auto;
  overflow-y: hidden;
}

.markdown-body .MathJax_Display {
  margin: 1em 0;
}

.markdown-body .MathJax_InlineMath {
  display: inline-block;
}
</style>
