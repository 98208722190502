<template>
  <div class="scrolling-text-container">
    <vue3-marquee
      :duration="60"
      :delay="0"
      :repeat="0"
      :clone="true"
      :gradient="false"
      class="marquee"
    >
      <span class="scrolling-text">
        {{ scrollingText }}
      </span>
    </vue3-marquee>
  </div>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";

export default {
  components: {
    Vue3Marquee,
  },
  data() {
    return {
      scrollingText:
        "Compiling abstract syntax trees... Optimizing tail recursion... Garbage collecting unused memories... Memoizing pure functions... Lazy evaluating infinite sequences... Proving type safety theorems... Synthesizing proofs from specifications...",
    };
  },
};
</script>

<style scoped>
.scrolling-text-container {
  width: 100%;
  background-color: rgba(27, 29, 42, 0.8);
  padding: 8px 0;
  border-radius: 4px;
  overflow: hidden;
}

.marquee {
  width: 100%;
}

.scrolling-text {
  font-family: "Fira Code", monospace;
  color: #5ccfe6;
  font-size: 14px;
  white-space: nowrap;
}

:deep(.v3m-scroll) {
  overflow: visible !important;
}
</style>
