<template>
  <div class="min-h-screen bg-terminal-bg text-terminal-text font-sans">
    <GridBackground />
    <div class="container-wrapper sm:px-8">
      <div
        class="max-w-4xl mx-auto px-4 py-4 sm:px-6 lg:px-8 relative z-10 bg-terminal-bg bg-opacity-80 rounded-lg shadow-lg content-container"
      >
        <header class="mb-4">
          <div class="flex items-center mb-2">
            <div class="mr-4">
              <div
                class="w-24 h-24 border-2 border-terminal-accent overflow-hidden"
              >
                <img
                  src="/media/jets.jpg"
                  alt="Jets"
                  class="w-full h-full object-cover"
                />
              </div>
            </div>
            <div>
              <h1
                class="text-4xl sm:text-5xl mb-1 text-terminal-highlight font-mono"
              >
                i am zaphx
              </h1>
              <div class="text-sm text-terminal-secondary italic">
                "Many such cases"
              </div>
            </div>
          </div>
          <NavMenu class="mb-2" />
          <ScrollingText class="mb-2" />
          <hr class="border-t-2 border-terminal-accent my-2" />
        </header>

        <main class="my-4">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </main>

        <footer class="mt-8 text-center text-terminal-muted">
          <hr class="border-t-2 border-terminal-accent my-8" />
          <p>&copy; 2024 zaph.dev</p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollingText from "./components/ScrollingText.vue";
import NavMenu from "./components/NavMenu.vue";
import GridBackground from "./components/GridBackground.vue";

export default {
  name: "App",
  components: {
    NavMenu,
    ScrollingText,
    GridBackground,
  },
  metaInfo() {
    return {
      meta: [
        {
          property: "og:title",
          content: "zaph.dev",
        },
        {
          property: "og:description",
          content:
            "pl researcher, category theorist, generalist theorem prover, the rest is an exercise for the reader",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://zaph.dev" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "@zaphlambda" },
        {
          name: "twitter:title",
          content: "zaph.dev",
        },
        {
          name: "twitter:description",
          content:
            "pl researcher, category theorist, generalist theorem prover, the rest is an exercise for the reader",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container-wrapper {
  position: relative;
  z-index: 10;
}

@media (min-width: 640px) {
  .container-wrapper {
    padding: 2rem 2rem;
  }
}

.content-container {
  transition: opacity 0.3s ease;
}

@media (min-width: 640px) {
  .content-container {
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(27, 29, 42, 0.5);
    border: 2px solid rgba(92, 207, 230, 0.1);
  }
}

.scrolling-text-container {
  position: relative;
  overflow: hidden;
}

.scrolling-text-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  background: linear-gradient(
    to left,
    var(--tw-bg-opacity-terminal-bg) 0%,
    transparent 100%
  );
}

:deep(.nav-menu a) {
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

:deep(.nav-menu a:hover) {
  color: var(--tw-text-opacity-terminal-highlight);
  text-shadow: 0 0 5px rgba(255, 121, 198, 0.5);
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
