<template>
  <div class="grid-container">
    <div class="grid-background"></div>
    <div class="depth-overlay"></div>
  </div>
</template>

<style scoped>
.grid-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 750px;
  overflow: hidden;
  z-index: 0;
}

.grid-background {
  position: absolute;
  top: -75%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-image: linear-gradient(
      to right,
      rgba(92, 207, 230, 0.07) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(92, 207, 230, 0.07) 1px, transparent 1px);
  background-size: 25px 25px;
  transform: rotateX(55deg) scale(2.5);
  animation: moveGrid 30s linear infinite;
}

@keyframes moveGrid {
  0% {
    transform: rotateX(55deg) translateY(0) scale(2.5);
  }
  100% {
    transform: rotateX(55deg) translateY(33.33%) scale(2.5);
  }
}

.depth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(27, 29, 42, 0.7) 0%,
    rgba(27, 29, 42, 0.5) 30%,
    rgba(27, 29, 42, 0.3) 60%,
    rgba(27, 29, 42, 0.1) 80%,
    transparent 100%
  );
  z-index: 1;
  pointer-events: none;
}

@media (max-width: 640px) {
  .grid-background {
    display: none;
  }
}
</style>
