<template>
  <div>
    <section aria-labelledby="about-heading" class="mb-8">
      <h2
        id="about-heading"
        class="text-2xl sm:text-3xl mb-4 text-terminal-accent font-mono"
      >
        who is zaphx?
      </h2>
      <div class="space-y-3 text-lg">
        <p class="fadeIn">Computer science and mathematics student</p>
        <p class="fadeIn">
          Research interests: Proof assistants, automated theorem proving, and
          formal methods
        </p>
        <p class="fadeIn">
          Languages: Python, Rust, C/C++, Java, Haskell, OCaml
        </p>
        <p class="fadeIn">Currently: Locking in</p>
      </div>
    </section>

    <section aria-labelledby="interests-heading" class="mb-8">
      <h2
        id="interests-heading"
        class="text-xl sm:text-2xl mb-3 text-terminal-blue font-mono"
      >
        Other Interests:
      </h2>
      <ul class="list-disc list-inside space-y-2 text-terminal-aqua">
        <li class="fadeIn">Recreational mathematics and puzzle-solving</li>
        <li class="fadeIn">
          <a
            class="text-terminal-accent"
            href="https://www.youtube.com/@zaphkiel_delta"
            >Music Production</a
          >
        </li>
      </ul>
    </section>

    <section aria-labelledby="socials-heading">
      <h2
        id="socials-heading"
        class="text-xl sm:text-2xl mb-3 text-terminal-blue font-mono"
      >
        Socials:
      </h2>
      <nav aria-label="Social Media Links" class="flex flex-wrap gap-3">
        <a
          v-for="social in socialMedia"
          :key="social.name"
          :href="social.url"
          target="_blank"
          rel="noopener noreferrer"
          class="px-3 py-1 bg-terminal-muted bg-opacity-20 text-terminal-highlight hover:text-terminal-accent transition-colors duration-300 rounded-full text-sm"
        >
          {{ social.name }}
        </a>
      </nav>
    </section>

    <section aria-labelledby="spotify-heading" class="mb-8">
      <div class="spotify-embed-container">
        <iframe
          style="border-radius: 12px"
          src="https://open.spotify.com/embed/track/4pNcKyJSsDhfqCdvxYhDF9?utm_source=generator&theme=0"
          width="80%"
          height="152"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
// import ActivityChart from "./ActivityChart.vue";

export default {
  // components: {
  //   ActivityChart,
  // },
  data() {
    return {
      socialMedia: [
        { name: "Github", url: "https://github.com/jfulfo" },
        { name: "X", url: "https://x.com/zaphlambda" },
        { name: "Spotify", url: "https://open.spotify.com/user/alsomistery" },
        {
          name: "Discord",
          url: "https://discordapp.com/users/455230073759465482",
        },
      ],
    };
  },
};
</script>

<style scoped>
.spotify-embed-container {
  margin: 0 auto;
  padding-top: 12px;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn:nth-child(1) {
  animation-delay: 0.1s;
}
.fadeIn:nth-child(2) {
  animation-delay: 0.2s;
}
.fadeIn:nth-child(3) {
  animation-delay: 0.3s;
}
.fadeIn:nth-child(4) {
  animation-delay: 0.4s;
}
.fadeIn:nth-child(5) {
  animation-delay: 0.5s;
}
</style>
