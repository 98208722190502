<template>
  <div class="theorem-prover">
    <h2 class="text-2xl mb-4 text-terminal-accent font-mono">
      Interactive Theorem Prover
    </h2>

    <div class="mb-4">
      <h3 class="text-xl mb-2 text-terminal-highlight">Premises:</h3>
      <div v-for="(premise, index) in premises" :key="index" class="mb-2">
        <input
          v-model="premises[index]"
          class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded"
        />
        <button
          @click="removePremise(index)"
          class="ml-2 text-terminal-muted hover:text-terminal-highlight"
        >
          Remove
        </button>
      </div>
      <button
        @click="addPremise"
        class="bg-terminal-accent text-terminal-bg px-4 py-2 rounded mt-2"
      >
        Add Premise
      </button>
    </div>

    <div class="mb-4">
      <h3 class="text-xl mb-2 text-terminal-highlight">Conclusion:</h3>
      <input
        v-model="conclusion"
        class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded w-full"
      />
    </div>

    <div class="mb-4">
      <h3 class="text-xl mb-2 text-terminal-highlight">Proof Steps:</h3>
      <div v-for="(step, index) in proofSteps" :key="index" class="mb-2">
        <select
          v-model="step.rule"
          @change="updateStep(index)"
          class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded mr-2"
        >
          <option value="">Select a rule</option>
          <option
            v-for="rule in inferenceRules"
            :key="rule.name"
            :value="rule.name"
          >
            {{ rule.name }}
          </option>
        </select>
        <input
          v-model="step.premise1"
          class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded mr-2"
          placeholder="Premise 1"
        />
        <input
          v-model="step.premise2"
          v-if="getRule(step.rule).premises === 2"
          class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded mr-2"
          placeholder="Premise 2"
        />
        <span class="text-terminal-text">⊢</span>
        <input
          v-model="step.conclusion"
          class="bg-terminal-bg text-terminal-text border border-terminal-muted p-2 rounded ml-2"
          placeholder="Conclusion"
        />
        <button
          @click="removeProofStep(index)"
          class="ml-2 text-terminal-muted hover:text-terminal-highlight"
        >
          Remove
        </button>
      </div>
      <button
        @click="addProofStep"
        class="bg-terminal-accent text-terminal-bg px-4 py-2 rounded mt-2"
      >
        Add Proof Step
      </button>
    </div>

    <button
      @click="verifyProof"
      class="bg-terminal-highlight text-terminal-bg px-4 py-2 rounded"
    >
      Verify Proof
    </button>

    <div
      v-if="proofResult"
      class="mt-4"
      :class="{
        'text-green-500': proofResult.valid,
        'text-red-500': !proofResult.valid,
      }"
    >
      <p>
        <strong>{{ proofResult.valid ? "Valid" : "Invalid" }}:</strong>
        {{ proofResult.message }}
      </p>
      <ul
        v-if="proofResult.errors.length > 0"
        class="list-disc list-inside mt-2"
      >
        <li v-for="(error, index) in proofResult.errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="mt-8">
      <h3 class="text-xl mb-2 text-terminal-highlight">Proof Tree:</h3>
      <div class="proof-tree">
        <ul>
          <li v-for="(premise, index) in premises" :key="'premise-' + index">
            {{ premise }}
          </li>
          <li v-for="(step, index) in proofSteps" :key="'step-' + index">
            <span>{{ step.premise1 }}</span>
            <span v-if="getRule(step.rule).premises === 2"
              >, {{ step.premise2 }}</span
            >
            <span> ⊢ {{ step.conclusion }} ({{ step.rule }})</span>
          </li>
          <li class="font-bold">∴ {{ conclusion }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      premises: ["P", "P → Q"],
      conclusion: "Q",
      proofSteps: [
        {
          rule: "Modus Ponens",
          premise1: "P",
          premise2: "P → Q",
          conclusion: "Q",
        },
      ],
      inferenceRules: [
        {
          name: "Modus Ponens",
          premises: 2,
          check: (p1, p2, c) => {
            const [antecedent, consequent] = p2.split("→").map((s) => s.trim());
            return p1 === antecedent && c === consequent;
          },
        },
        {
          name: "Modus Tollens",
          premises: 2,
          check: (p1, p2, c) => {
            const [antecedent, consequent] = p2.split("→").map((s) => s.trim());
            return (
              p1 === `¬${consequent.trim()}` && c === `¬${antecedent.trim()}`
            );
          },
        },
        {
          name: "Hypothetical Syllogism",
          premises: 2,
          check: (p1, p2, c) => {
            const [a, b] = p1.split("→").map((s) => s.trim());
            const [, d] = p2.split("→").map((s) => s.trim());
            return b === p2.split("→")[0].trim() && c === `${a} → ${d}`;
          },
        },
        {
          name: "Disjunctive Syllogism",
          premises: 2,
          check: (p1, p2, c) => {
            const [a, b] = p1.split("∨").map((s) => s.trim());
            return (p2 === `¬${a}` && c === b) || (p2 === `¬${b}` && c === a);
          },
        },
        {
          name: "Double Negation",
          premises: 1,
          check: (p1, _, c) => p1 === `¬¬${c}` || `¬¬${p1}` === c,
        },
      ],
      proofResult: null,
    };
  },
  methods: {
    addPremise() {
      this.premises.push("");
    },
    removePremise(index) {
      this.premises.splice(index, 1);
    },
    addProofStep() {
      this.proofSteps.push({
        rule: "",
        premise1: "",
        premise2: "",
        conclusion: "",
      });
    },
    removeProofStep(index) {
      this.proofSteps.splice(index, 1);
    },
    updateStep(index) {
      const step = this.proofSteps[index];
      const rule = this.getRule(step.rule);
      if (rule && rule.premises === 1) {
        step.premise2 = "";
      }
    },
    getRule(ruleName) {
      return (
        this.inferenceRules.find((r) => r.name === ruleName) || { premises: 0 }
      );
    },
    verifyProof() {
      const validationResult = this.checkProofValidity();
      this.proofResult = {
        valid: validationResult.isValid,
        message: validationResult.isValid
          ? "Proof is valid!"
          : "Proof is invalid. Please check the errors below.",
        errors: validationResult.errors,
      };
    },
    checkProofValidity() {
      const validPropositions = new Set(
        this.premises.filter((p) => p.trim() !== "")
      );
      const errors = [];

      for (let i = 0; i < this.proofSteps.length; i++) {
        const step = this.proofSteps[i];
        const rule = this.getRule(step.rule);

        if (!rule || rule.premises === 0) {
          errors.push(`Step ${i + 1}: Invalid or missing rule`);
          continue;
        }

        if (!validPropositions.has(step.premise1)) {
          errors.push(
            `Step ${i + 1}: Invalid first premise: "${step.premise1}"`
          );
        }
        if (rule.premises === 2 && !validPropositions.has(step.premise2)) {
          errors.push(
            `Step ${i + 1}: Invalid second premise: "${step.premise2}"`
          );
        }

        if (!rule.check(step.premise1, step.premise2, step.conclusion)) {
          errors.push(
            `Step ${i + 1}: "${step.rule}" rule application is incorrect`
          );
        }

        validPropositions.add(step.conclusion);
      }

      if (!validPropositions.has(this.conclusion)) {
        errors.push(
          `The conclusion "${this.conclusion}" was not reached in the proof`
        );
      }

      return {
        isValid: errors.length === 0,
        errors: errors,
      };
    },
  },
};
</script>

<style scoped>
.theorem-prover {
  @apply bg-terminal-bg bg-opacity-50 border border-terminal-muted border-opacity-30 rounded-lg p-6;
}

.proof-tree {
  @apply mt-4 pl-4 border-l-2 border-terminal-accent;
}

.proof-tree ul {
  @apply list-none;
}

.proof-tree li {
  @apply mb-2;
}
</style>
